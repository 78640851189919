var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-template', [_c('v-layout', {
    attrs: {
      "justify-center": "",
      "align-start": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100wv"
    }
  }, [_c('div', {
    staticClass: "sub-visual-container sv1"
  }, [_c('div', {
    staticClass: "sub-visual-wrapper"
  }, [_c('h1', {
    staticClass: "sub-visual--title"
  }, [_vm._v(_vm._s(_vm.siteInfo.serviceNameKo || _vm.siteInfo.projectNameKo) + " 소개")])])]), _c('div', {
    staticClass: "sub-container sub-container__new",
    staticStyle: {
      "max-width": "1230px",
      "width": "100%",
      "margin": "0 auto"
    }
  }, [_c('div', {
    staticClass: "sub-section"
  }, [_c('div', {
    staticClass: "sub-section-introduce d-flex flex-column align-center"
  }, [_c('div', {
    staticClass: "section-logo"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/mbsroom/intro-logo.png",
      "alt": "logo"
    }
  })], 1), _c('div', {
    staticClass: "text-area"
  }, [_c('h3', {
    staticClass: "text-area__tit"
  }, [_vm._v("안녕하세요 "), _c('br', {
    staticClass: "d-flex d-none d-sm-none d-md-none"
  }), _vm._v("모바일쿠폰 전문 쇼핑몰 멤버스에스룸 입니다.")]), _c('p', {
    staticClass: "text-area__sentence"
  }, [_vm._v(" 저희 모바일 핀번호 발송 서비스는 안정적이고 정확한 발송 시스템을 갖추고 있으며, 보안 시스템을 도입하여 안전하게 서비스를 제공하고 있습니다."), _c('br', {
    staticClass: "d-none d-lg-flex"
  }), _vm._v(" 항상 고객 만족을 최우선으로 생각하며, 언제든지 문의사항이 있으면 친절하고 신속하게 답변해 드리겠습니다. ")])]), _c('div', {
    staticClass: "section-bg"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/mbsroom/intro-bg.png",
      "alt": "logo"
    }
  })], 1)])])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }